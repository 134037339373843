import { Button, Table, TableColumn, Dialog, Input, Select, Option, Form, FormItem } from 'element-ui';
import backStageHeader from '@/components/backStageHeader';
import searchInput from '@/components/searchInput';
import qlPagination from '@/components/qlPagination';
// api
import { getQuantongPage, removeQuantong, getRegisterSchoolList, saveQuantong } from '@/api/back-stage';

export default {
  name: 'allPassSingleSign',
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    backStageHeader,
    searchInput,
    qlPagination,
  },
  data() {
    return {
      schoolKeyword: '',

      // 表格
      listData: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },

      visible: false,
      schoolList: [], // 青小鹿学校列表
      form: {
        id: '',
        schoolKey: '',
        thirdPartySchoolId: '',
      },
      rules: {
        schoolKey: [{ required: true, message: '青小鹿学校名称不能为空' }],
        thirdPartySchoolId: [{ required: true, message: '全通学校ID不能为空', trigger: 'blur' }],
      },
    };
  },
  methods: {
    // 按学校名搜索
    searchData() {
      this.getDataList(true);
    },
    // 加载表格数据
    getDataList(reset = false) {
      reset === true && (this.listData.pageIndex = 1);
      let { pageIndex, pageSize } = this.listData;
      getQuantongPage({
        pageIndex,
        pageSize,
        schoolNameFuzzy: this.schoolKeyword,
      }).then((res) => {
        if (res.result) {
          this.listData.list = res.result.data;
          this.listData.total = res.result.recordCount;
        } else {
          this.listData.list = [];
          this.listData.total = 0;
        }
      });
    },
    // 切换条数
    handleSizeChange(val) {
      this.listData.pageSize = val;
      this.getDataList(true);
    },
    // 切换页码
    handleCurrentChange(val) {
      this.listData.pageIndex = val;
      this.getDataList();
    },
    // 新增学校
    handleAdd() {
      this.visible = true;
    },
    // 编辑
    handleEdit(row) {
      this.visible = true;
      let { id, schoolKey, schoolName, thirdPartySchoolId } = row;
      this.form = { id, schoolKey, thirdPartySchoolId };
      this.querySearchAsync(schoolName);
    },
    // 删除
    handleDelete(row) {
      this.$msgbox
        .confirm('确定删除关联吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
        .then(() => {
          removeQuantong({
            id: row.id,
          }).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
            // 判断是否为最后一页唯一一条数据
            let idx = this.listData.pageIndex;
            if (idx > 1 && this.listData.list && this.listData.list.length === 1) {
              idx--;
            }
            this.handleCurrentChange(idx);
          });
        })
        .catch(() => {});
    },
    // 远程搜索注册学校
    querySearchAsync(query) {
      if (query !== '') {
        getRegisterSchoolList({
          schoolNameFuzzy: query,
        }).then((res) => {
          this.schoolList = res.result;
        });
      } else {
        this.schoolList = [];
      }
    },
    // 保存
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let { id, ...form } = this.form;
          let params = id ? this.form : form;
          saveQuantong(params).then(() => {
            this.$message.success('保存成功！');
            this.visible = false;
            this.getDataList();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 表单重置
    resetForm() {
      this.$refs.form.resetFields();
      for (let i in this.form) {
        this.form[i] = '';
      }
    },
  },
  mounted() {
    this.getDataList();
  },
};
